<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus
            :title="$t('components.blacklistRiderManagement.headline')"
            @plus="makeBlacklistUser"
          />

          <div class="md:flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="
              $t(
                'components.blacklistRiderManagement.summary.totalBlacklistRiders'
              )
            "
            :value="
              getMetaData && getMetaData.summary ? getMetaData.summary.total : 0
            "
            variant="gray"
          />
        </div>
      </div>

      <FSTable
        :fst-id="`blacklistRider`"
        :endpoint="indexDataEndpoint"
        :headers="tableHeaders"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="`blacklistRider`"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <FSTableRow
            v-for="(item, index) in data"
            :key="`fs-table-row-${index}`"
          >
            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem
              v-if="item.is_anonymous_user"
              :text="item.phone_number"
            />
            <FSTableRowItem
              v-else
              :text="item.phone_number"
              :to="{
                name: 'ViewRiderUserProfile',
                params: { id: item.rider_id },
              }"
              target="_blank"
            />
            <FSTableRowItem>
              <b>{{ item.ip_address }}</b>
            </FSTableRowItem>
            <FSTableRowItem :text="item.reason" />

            <FSTableRowItem :text="getUserType(item.is_anonymous_user)" />
            <!-- <FSTableRowItem :text="getTimeAgo(item.created_at)"> -->
            <FSTableRowItem>
              <TimeAgo
                :title="item.created_at"
                :datetime="item.created_at"
                :auto-update="60"
              />
            </FSTableRowItem>

            <FSTableRowItem>
              <t-dropdown variant="smActions">
                <template
                  #trigger="{
                      mousedownHandler,
                      focusHandler,
                      blurHandler,
                      keydownHandler,
        }"
                >
                  <button
                    class="focus:outline-none"
                    aria-label="More Actions"
                    aria-haspopup="true"
                    @mousedown="mousedownHandler"
                    @focus="focusHandler"
                    @blur="blurHandler"
                    @keydown="keydownHandler"
                  >
                    <svg
                      class="mt-1 w-6 h-5 text-oGray hover:text-oDark"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      ></path>
                    </svg>
                  </button>
                </template>

                <template #default="{ hide }">
                  <div
                    @click="hide()"
                    class="py-1 mt-px bg-white rounded-md shadow-md"
                  >
                    <t-dropdown-item @click="makeWhitelist(item.ip_address)"
                      >{{
                        $t(
                          'components.blacklistRiderManagement.actions.makeWhitelist'
                        )
                      }}
                    </t-dropdown-item>
                  </div>
                </template>
              </t-dropdown>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
    <!-- TODO: Refactoring modal into a component -->
    <t-modal
      name="blacklist-rider"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="true"
      :clickToClose="true"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        {{
          $t(
            'components.blacklistRiderManagement.modals.makeWhitelist.headline'
          )
        }}
      </section>

      <div class="px-5 pt-5">
        <form @submit.prevent="handleSubmit()" enctype="multipart/form-data">
          <AppInput
            type="richselect"
            name="phone-ip"
            :label="
              $t(
                'components.blacklistRiderManagement.modals.makeWhitelist.title.selectIpOrPhoneNumber'
              )
            "
            :placeholder="
              $t(
                'components.blacklistRiderManagement.modals.makeWhitelist.placeholder.selectIpOrPhoneNumber'
              )
            "
            :options="methods"
            hide-search-box
            v-model="formData.key"
          />
          <AppInput
            v-model="formData.value"
            rules=""
            :label="
              $t(
                'components.blacklistRiderManagement.modals.makeWhitelist.title.write'
              )
            "
            type="text"
            name="value"
          />
          <button
            type="submit"
            ref="makeBlacklistSubmitButtonRef"
            class="hidden"
          >
            Save
          </button>
        </form>
      </div>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton :variant="`secondary`" :class="`w-1/2`" @click="closeModal">
          {{ $t('components.stepNavigation.cancel') }}
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :isLoading="isLoading"
          @click="$refs.makeBlacklistSubmitButtonRef.click()"
        >
          {{
            $t(
              'components.blacklistRiderManagement.modals.makeWhitelist.action.makeBlacklist'
            )
          }}
        </AppButton>
      </section>
    </t-modal>
  </base-layout>
</template>

<script>
import { BlackListedRiderUserConfig } from '@/config/BlackListedRiderUserConfig'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import SummaryCard from '@/components/cards/SummaryCard'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
export default {
  name: 'BlackListedRiderIndex',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    TDropdownItem,
    SummaryCard,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
  },
  data() {
    return {
      isLoading: false,
      filterOptions: [],
      indexDataEndpoint: BlackListedRiderUserConfig.api.index,
      tableHeaders: [
        {
          text: this.$t('components.blacklistRiderManagement.table.columns.id'),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.blacklistRiderManagement.table.columns.phoneNumber'
          ),
          width: '10%',
          sort: 'phone_number',
        },
        {
          text: this.$t(
            'components.blacklistRiderManagement.table.columns.ipAddress'
          ),
          width: '10%',
          sort: 'ip_address',
        },
        {
          text: this.$t(
            'components.blacklistRiderManagement.table.columns.reason'
          ),
          width: '30%',
          sort: 'reason',
        },
        {
          text: this.$t(
            'components.blacklistRiderManagement.table.columns.userType'
          ),
          width: '15%',
          sort: 'is_anonymous_user',
        },
        {
          text: this.$t(
            'components.blacklistRiderManagement.table.columns.blacklistedDate'
          ),
          width: '10%',
          sort: 'created_at',
        },
        {
          text: this.$t(
            'components.blacklistRiderManagement.table.columns.actions'
          ),
          width: '10%',
          sort: null,
        },
      ],
      isMakeBlacklistModalShow: false,
      methods: [
        {
          text: 'Phone Number',
          value: 'phone_number',
        },
        {
          text: 'IP Address',
          value: 'ip_address',
        },
      ],
      formData: {
        key: '',
        value: '',
      },
    }
  },
  // async created() {
  //   await this.generateFilterOptions()
  // },
  computed: {
    getMetaData() {
      return this.$store.getters['fsTable/getMeta']
    },
  },

  methods: {
    // getTimeAgo(time) {
    //   //offset in hours

    //   // const tz = this.$dayjs.tz.guess()
    //   if (!this.$dayjs(time).isValid()) {
    //     return '--'
    //   }

    //   const clientTimezoneOffset = (new Date().getTimezoneOffset() / 60) * -1
    //   const rawTime = this.$dayjs(time)
    //     .add(clientTimezoneOffset , 'hour')
    //     .fromNow()
    //   const actualTime =
    //     rawTime.split('in')[0] === ''
    //       ? rawTime.split('in')[1] + ' ago'
    //       : rawTime.split('in')[0]
    //   return actualTime
    // },
    makeBlacklistUser() {
      this.$modal.show('blacklist-rider')
    },
    async handleSubmit() {
      this.isLoading = true
      const data = new FormData()
      data.append(this.formData.key, this.formData.value.trim())
      data.append('fraud_status', 'B')

      await this.$http
        .post(BlackListedRiderUserConfig.api.blockOrWhiteUser, data)
        .then((res) => {
          console.log('successful-Blocklisted-response', res)
          if (res.data?.message) {
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: 'Failed',
                text: res.data.message,
              },
              3000
            )
            this.isLoading = false
          } else {
            this.$notify(
              {
                group: 'generic',
                type: 'success',
                title: 'BlockListed',
                text: res.data.fraud_status_reason,
              },
              3000
            )
            this.isLoading = false

            this.$modal.hide('blacklist-rider')

            this.$store.dispatch('fsTable/fetchData')

            this.formData.key = ''
            this.formData.value = ''
          }
        })
        .catch((err) => {
          console.log('unsuccessful-blocklisted-response', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to make Blocklisted',
            },
            3000
          )
          this.isLoading = false
        })
    },
    closeModal() {
      this.$modal.hide('blacklist-rider')
    },
    getUserType(status) {
      return status ? 'Anonymous' : 'Rider'
    },
    onApplyFilterDateRange,
    async makeWhitelist(ip) {
      console.log('hit from blacklist and ID:', ip)
      const data = new FormData()
      data.append('ip_address', ip)
      data.append('fraud_status', 'N')
      await this.$http
        .post(BlackListedRiderUserConfig.api.blockOrWhiteUser, data)
        .then((res) => {
          console.log('successful-whitelisted-response', res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'WhiteListed',
              text: 'Successfully whitelisted to the User.',
            },
            3000
          )
          this.$store.dispatch('fsTable/fetchData')
        })
        .catch((err) => {
          console.log('unsuccessful-whitelisted-response', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to make whitelisted',
            },
            3000
          )
        })
    },
  },
}
</script>
