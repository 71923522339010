var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between"},[_c('title-plus',{attrs:{"title":_vm.$t('components.blacklistRiderManagement.headline')},on:{"plus":_vm.makeBlacklistUser}}),_c('div',{staticClass:"md:flex md:justify-end"},[_c('DateRangePicker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)],1),_c('div',{staticClass:"flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.blacklistRiderManagement.summary.totalBlacklistRiders'
            ),"value":_vm.getMetaData && _vm.getMetaData.summary ? _vm.getMetaData.summary.total : 0,"variant":"gray"}})],1)]),_c('FSTable',{attrs:{"fst-id":"blacklistRider","endpoint":_vm.indexDataEndpoint,"headers":_vm.tableHeaders},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
            var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":"blacklistRider","slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
            var data = ref.data;
return _vm._l((data),function(item,index){return _c('FSTableRow',{key:("fs-table-row-" + index)},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5}}),(item.is_anonymous_user)?_c('FSTableRowItem',{attrs:{"text":item.phone_number}}):_c('FSTableRowItem',{attrs:{"text":item.phone_number,"to":{
              name: 'ViewRiderUserProfile',
              params: { id: item.rider_id },
            },"target":"_blank"}}),_c('FSTableRowItem',[_c('b',[_vm._v(_vm._s(item.ip_address))])]),_c('FSTableRowItem',{attrs:{"text":item.reason}}),_c('FSTableRowItem',{attrs:{"text":_vm.getUserType(item.is_anonymous_user)}}),_c('FSTableRowItem',[_c('TimeAgo',{attrs:{"title":item.created_at,"datetime":item.created_at,"auto-update":60}})],1),_c('FSTableRowItem',[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"focus:outline-none",attrs:{"aria-label":"More Actions","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('svg',{staticClass:"mt-1 w-6 h-5 text-oGray hover:text-oDark",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"}})])])]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{on:{"click":function($event){return _vm.makeWhitelist(item.ip_address)}}},[_vm._v(_vm._s(_vm.$t( 'components.blacklistRiderManagement.actions.makeWhitelist' ))+" ")])],1)]}}],null,true)})],1)],1)})}}])})],1),_c('t-modal',{attrs:{"name":"blacklist-rider","variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true,"escToClose":true,"clickToClose":true}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.$t( 'components.blacklistRiderManagement.modals.makeWhitelist.headline' ))+" ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('AppInput',{attrs:{"type":"richselect","name":"phone-ip","label":_vm.$t(
              'components.blacklistRiderManagement.modals.makeWhitelist.title.selectIpOrPhoneNumber'
            ),"placeholder":_vm.$t(
              'components.blacklistRiderManagement.modals.makeWhitelist.placeholder.selectIpOrPhoneNumber'
            ),"options":_vm.methods,"hide-search-box":""},model:{value:(_vm.formData.key),callback:function ($$v) {_vm.$set(_vm.formData, "key", $$v)},expression:"formData.key"}}),_c('AppInput',{attrs:{"rules":"","label":_vm.$t(
              'components.blacklistRiderManagement.modals.makeWhitelist.title.write'
            ),"type":"text","name":"value"},model:{value:(_vm.formData.value),callback:function ($$v) {_vm.$set(_vm.formData, "value", $$v)},expression:"formData.value"}}),_c('button',{ref:"makeBlacklistSubmitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.makeBlacklistSubmitButtonRef.click()}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.blacklistRiderManagement.modals.makeWhitelist.action.makeBlacklist' ))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }